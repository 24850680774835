<template>
	<div class="quicky-cart-area">
		<div class="container">
			<div class="row">
				<div class="col-12">
					<h2 class="text-center" style="padding-bottom: 30px">
						Historial de solicitudes
					</h2>
					<form action="javascript:void(0)">
						<div class="table-content table-responsive">
							<div v-if="orders != ''">
								<table class="table">
									<thead>
										<tr>
											<th class="quicky-product-thumbnail">Orden</th>
											<th class="quicky-product-thumbnail">Items</th>
											<th class="quicky-product-thumbnail">Status</th>
											<th class="quicky-product-quantity">Fecha</th>
											<th class="quicky-product-quantity">Opciones</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="order in orders"
											:key="order.id"
											v-if="order.items.length > 0"
										>
											<td>{{ order.id }}</td>
											<td>{{ order.items.length }}</td>
											<td>{{ order.orde_status | toSpanishStatus }}</td>
											<td>
												{{ order.created_at | toDateFormat }}
											</td>
											<td>
												<!--<a v-if="
                            order.orde_status != 'paid' &&
                              order.orde_status != 'rejected'
                          " style="padding-right:5px;" title="Pagar orden" @click.prevent="payOrder(order)" data-toggle="tooltip"><i class="fa fa-money" style="vertical-align: middle;"></i>
                      </a>-->
												<a
													title="Ver orden"
													@click.prevent="showOrder(order)"
													data-toggle="tooltip"
													><i class="fa fa-eye" style="vertical-align: middle"></i>
												</a>
												<a
													v-if="
														order.orde_status != 'paid' && order.orde_status != 'rejected'
													"
													style="padding-right: 5px"
													title="Elimiar Item"
													@click.prevent="cancelOrder(order.id)"
													data-toggle="tooltip"
													><i class="material-icons" style="vertical-align: middle"></i>
												</a>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div v-else>
								<h3
									class="text-center"
									style="padding-top: 30px"
									v-if="tableLength === ''"
								>
									Cargando solicitudes
								</h3>
								<h3
									class="text-center"
									style="padding-top: 30px"
									v-if="tableLength === 0"
								>
									Aún no haz realizado solicitudes
								</h3>
							</div>
						</div>
					</form>
				</div>
			</div>
			<div class="row" v-if="showDetails">
				<div
					class="col-md-11 col-lg-10 col-sm-12 col-xs-12 center-block"
					style="margin: auto"
				>
					<OrderDetailSection :orderDetails="this.orderDetails"></OrderDetailSection>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	import OrderDetailSection from '@/components/sections/OrderDetailSection'

	export default {
		components: {
			OrderDetailSection,
		},
		data() {
			return {
				config: {
					headers: {
						'Content-Type': 'application/json',
						'X-Requested-With': 'XMLHttpRequest',
						Authorization: sessionStorage.getItem('token')
							? 'Bearer ' + sessionStorage.getItem('token')
							: '',
					},
				},
				orderDetails: '',
				tax: process.env.VUE_APP_TAX,
				orders: [],
				showDetails: false,
				isVerified: false,
				tableLength: '',
			}
		},
		computed: {
			totalAmount() {
				let total = 0
				total = (parseFloat(this.taxAmount) + parseFloat(this.subtotal)).toFixed(2)
				return total
			},
			taxAmount() {
				let tax = 0
				tax = (parseFloat(this.subtotal) * (parseFloat(this.tax) / 100)).toFixed(2)
				return tax
			},
		},
		created() {
			this.getOrders()
		},
		filters: {
			toPlatformName: function (platId) {
				if (!platId) return ''
				let platformName
				switch (platId) {
					case 2:
						platformName = 'PayForU'
						break
					case 3:
						platformName = 'MallLikeU'
						break
					case 4:
						platformName = 'Willi-U'
						break
					case 5:
						platformName = 'Ruta-U'
						break
					default:
						platformName = platId
						break
				}
				return platformName
			},

			toSpanishStatus: function (status) {
				if (!status) return ''
				let spanishStatus
				switch (status) {
					case 'pending':
						spanishStatus = 'Pendiente'
						break
					case 'to pay':
						spanishStatus = 'Por pagar'
						break
					case 'rejected':
						spanishStatus = 'Rechazada'
						break
					case 'paid':
						spanishStatus = 'Pagada'
						break
					case 'canceled':
						spanishStatus = 'Anulada'
						break
					default:
						spanishStatus = 'Otro'
						break
				}
				return spanishStatus
			},

			toDateFormat(datetime) {
				if (!datetime) return ''
				let dateString = new Date(datetime)

				return dateString.toLocaleString()
			},
		},
		methods: {
			async showOrder(order) {
				this.orderDetails = order
				this.showDetails = true
			},
			async payOrder(order) {
				axios
					.get(
						process.env.VUE_APP_CART_API +
							'api/orders/' +
							order.orde_token +
							'/status/to pay',
						this.config
					)
					.then((response) => {
						sessionStorage.removeItem('orderToPay')
						sessionStorage.setItem('orderToPay', JSON.stringify(order))
						this.$router.push('/confirmar')
					})
					.catch((error) => {
						console.log(error.response)
						this.error = true
					})
			},
			async deleteItem(itemId) {
				axios
					.delete(process.env.VUE_APP_CART_API + 'api/items/' + itemId, this.config)
					.then((response) => {
						this.getOrders()
					})
					.catch((error) => {
						console.log(error)
						this.error = true
					})
			},
			async getOrders() {
				axios
					.get(process.env.VUE_APP_CART_API + 'api/orders/12/all', this.config)
					.then((response) => {
						this.orders = response.data.orders
						this.tableLength = this.orders.length
						sessionStorage.setItem('ordersNumber', this.orders.length)
					})
					.catch((error) => {
						console.log(error)
						this.error = true
					})
			},

			async cancelOrder(orderId) {
				axios
					.post(process.env.VUE_APP_CART_API + 'api/cancel-order/' + orderId, this.config)
					.then((response) => {
						this.getOrders()
					})
					.catch((error) => {
						console.log(error)
						this.error = true
					})
			},

			async checkVerified() {
				let urlCheckVerified = process.env.VUE_APP_AUTH_API + 'api/verified/user'
				axios
					.get(urlCheckVerified, this.config)
					.then((response) => {
						//console.log(response.data);
						if (response.data.verification == true) {
							this.isVerified = true
						} else {
							this.isVerified = false
							this.$swal({
								imageUrl: 'https://vistapointe.net/images/lock-4.jpg',
								imageWidth: 300,
								title: `¡Hola ` + sessionStorage.getItem('name') + `!`,
								html: `Antes de continuar, iniciaremos un proceso rutinario de verificación de datos. <br>
                Esto nos permite mantener una relacion más segura contigo`,
								showCancelButton: false,
								confirmButtonColor: '#003d74',
								showConfirmButton: true,
								confirmButtonText: 'Aceptar',
							}).then((result) => {
								if (result.isConfirmed) {
									this.$router.push({
										name: 'VerifyUser',
										query: {
											email: response.data.email,
										},
									})
								}
							})
						}
						return this.isVerified
					})
					.catch((error) => {
						this.$swal({
							title: 'Parece que ocurrió un error...',
							text: 'Comprueba tu conexión de internet e intenta de nuevo',
							showCancelButton: false,
							showConfirmButton: true,
						}).then((result) => {
							if (result.isConfirmed) {
								location.reload()
							}
						})
						this.$swal.showValidationMessage(error)
						console.log(error)
						this.error = true
					})
			},

			async authorizePurchase() {
				await this.checkVerified()
				await this.changeStatus()
			},

			async changeStatus() {
				axios
					.get(
						process.env.VUE_APP_CART_API +
							'api/orders/' +
							JSON.parse(sessionStorage.getItem('order')).orde_token +
							'/status/to pay',
						this.config
					)
					.then((response) => {
						sessionStorage.removeItem('order')
						sessionStorage.removeItem('items')
						this.$router.push('/confirmar')
					})
					.catch((error) => {
						console.log(error.response)
						this.error = true
					})
			},
		},
	}
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/main.scss';

	.quicky-cart-area {
		padding: 40px 0px !important;
	}

	.ht-btn--round {
		padding: 10px;
		padding-top: 5px;
		padding-bottom: 5px;
		margin-top: 20px;
		font-size: 20px;
		background-color: #e94e1b;
	}

	.table-content table td {
		border-top: medium none;
		padding: 18px 10px;
		vertical-align: middle;
		font-size: 17px;
	}

	.table-content table th {
		font-size: 18px;
		font-weight: bold;
	}
</style>
