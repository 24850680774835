<template>
	<div v-if="orderDetails != ''" class="quicky-cart-area">
		<div class="container">
			<div class="row">
				<div class="col-12">
					<h2 class="text-center" style="padding: 30px">
						Detalle de la Orden {{ orderDetails.id }}
					</h2>
					<form action="javascript:void(0)">
						<div class="table-content table-responsive">
							<div v-if="items">
								<table class="table">
									<thead>
										<tr>
											<th class="quicky-product-thumbnail">Items</th>
											<th class="cart-product-name">Plataforma</th>
											<!--<th class="quicky-product-price">Precio unitario</th>
                    <th class="quicky-product-quantity">Cantidad</th>
                    <th class="quicky-product-quantity">Total</th>-->
											<th class="quicky-product-quantity"></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="item in orderDetails.items" :key="item.id">
											<td>{{ item.item_description }}</td>
											<td>{{ item.plat_id | toPlatformName }}</td>
											<!--<td class="text-right">
												{{ orderDetails.orde_currency }}
												{{ parseFloat(item.item_price).toFixed(2) }}
											</td>
											<td>{{ item.item_quantity }}</td>
											<td class="text-right">
												{{ orderDetails.orde_currency }}
												{{ parseFloat(item.item_price * item.item_quantity).toFixed(2) }}
											</td>-->
											<td>
												<a
													v-if="
														orderDetails.orde_status != 'paid' &&
														orderDetails.orde_status != 'rejected' &&
														orderDetails.items.length > 1
													"
													class="delete"
													title="Elimiar Solicitud"
													@click.prevent="deleteItem(item.id)"
													data-toggle="tooltip"
													><i class="material-icons"></i>
												</a>
											</td>
										</tr>
									</tbody>
								</table>
								<!--<div class="row">
									<div class="col-md-5 ml-auto">
										<div class="cart-page-total" style="padding-bottom: 30px">
											<h2>Resumen</h2>
											<ul>
												<li>
													Subtotal
													<span
														>{{ orderDetails.orde_currency }}
														{{ orderDetails.orde_total }}</span
													>
												</li>
												<li>
													IVA ({{ tax }}%)
													<span>{{ orderDetails.orde_currency }} {{ taxAmount }}</span>
												</li>
												<li>
													Total
													<span>{{ orderDetails.orde_currency }} {{ totalAmount }}</span>
												</li>
											</ul>-->
								<!-- <router-link @click="changeStatus" to="#">Confirmar compra</router-link> -->
								<!--</div>
									</div>
								</div>-->
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'

	export default {
		props: ['orderDetails'],
		name: 'OrderDetailSection',
		data() {
			return {
				config: {
					headers: {
						Authorization: `Bearer ` + sessionStorage.getItem('token'),
					},
				},
				currency: this.orderDetails.orde_currency,
				subtotal: this.orderDetails.orde_total,
				tax: process.env.VUE_APP_TAX,
				items: this.orderDetails.items,
				isVerified: false,
			}
		},
		created() {
			this.currency = this.orderDetails.orde_currency
			this.subtotal = this.orderDetails.orde_total
			this.tax = process.env.VUE_APP_TAX
			this.items = this.orderDetails.items
		},
		computed: {
			totalAmount() {
				let total = 0
				total = (
					parseFloat(this.taxAmount) + parseFloat(this.orderDetails.orde_total)
				).toFixed(2)
				return total
			},
			taxAmount() {
				let tax = 0
				tax = (
					parseFloat(this.orderDetails.orde_total) *
					(parseFloat(this.tax) / 100)
				).toFixed(2)
				return tax
			},
		},
		filters: {
			toPlatformName: function (platId) {
				if (!platId) return ''
				let platformName
				switch (platId) {
					case 2:
						platformName = 'PayForU'
						break
					case 3:
						platformName = 'MallLikeU'
						break
					case 4:
						platformName = 'Willi-U'
						break
					case 5:
						platformName = 'Ruta-U Negocios'
						break
					case 17:
						platformName = 'Ruta-U Agentes'
						break
					case 6:
						platformName = 'Lili-U'
						break
					case 7:
						platformName = 'U-Konga'
						break
					case 8:
						platformName = 'Euro-ComEx'
						break
					case 9:
						platformName = 'MallLikeU-B2B'
						break
					case 10:
						platformName = 'MallLikeU-B2C'
						break
					case 11:
						platformName = 'MallLikeU-B2C'
						break
					case 18:
						platformName = 'Farma-U'
						break
					case 19:
						platformName = 'Pets-U'
						break
					case 20:
						platformName = 'LikeU-Creative'
						break
					default:
						platformName = platId
						break
				}
				return platformName
			},
		},
		methods: {
			async deleteItem(itemId) {
				this.$swal({
					position: 'center',
					title: '¿Desea eliminar?',
					confirmButtonColor: '#41b882',
					cancelButtonColor: '#ff7674',
					showCancelButton: true,
					icon: 'info',
					confirmButtonText: 'Si',
					cancelButtonText: 'No',
					showCloseButton: true,
					showLoaderOnConfirm: true,
				}).then((result) => {
					if (result.value) {
						axios
							.delete(process.env.VUE_APP_CART_API + 'api/items/' + itemId, this.config)
							.then(() => {
								location.reload()
								this.$swal({
									position: 'center',
									title: 'Solicitud eliminada',
									showConfirmButton: false,
									timer: 1500,
									icon: 'success',
								})
							})
							.catch((error) => {
								this.$swal({
									position: 'center',
									title: 'Ocurrió un error',
									html:
										'<h3 class="text-center">Compruebe su conexión a internet e intente nuevamente</h3>',
									icon: 'error',
									showConfirmButton: true,
									confirmButtonText: 'Aceptar',
								}).then((result) => {
									if (result.isConfirmed) {
										location.reload()
									}
								})
								this.$swal.showValidationMessage(`Solicitud rechazada: ${error}`)
								console.log(error.response)
								this.error = true
							})
					}
				})
			},
		},
	}
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/main.scss';

	.quicky-cart-area {
		padding: 20px 0;
	}

	.ht-btn--round {
		padding: 10px;
		padding-top: 5px;
		padding-bottom: 5px;
		margin-top: 20px;
		font-size: 20px;
		background-color: #e94e1b;
	}

	.table-content table td {
		border-top: medium none;
		padding: 18px 10px;
		vertical-align: middle;
		font-size: 17px;
	}

	.table-content table th {
		font-size: 18px;
		font-weight: bold;
	}
</style>
